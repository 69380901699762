import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { useNavigate } from 'react-router-dom';
import { actions } from '../../store/Profile';
import Robot from './Robot';
import Button from '../Button';

import './Error.css';

function Error({ error }) {
    const navigate = useNavigate();

    const redirect = () => {
        navigate('/');
    };

    const getTitle = statusCode => {
        switch (statusCode) {
            case 403: {
                return 'You are not authorized';
            }

            default: {
                return 'Unexpected error';
            }
        }
    };

    const getMessage = statusCode => {
        switch (statusCode) {
            case 403: {
                return 'You do not have permissions to request this page';
            }

            default: {
                return 'Sorry something went wrong on the server';
            }
        }
    };

    if (error.statusCode == null) {
        return (
            <div className="Error">
                <h1>Please try again</h1>
                <Button className="Error__Button" onClick={redirect} label="Try again" />
            </div>
        );
    }

    const { statusCode, correlationId } = error;
    return (
        <div className="Error">
            <div className="Error__Content">
                <Robot />
                <div>
                    <h1>{getTitle(statusCode)}</h1>

                    <h3>{getMessage(statusCode)}</h3>

                    <div className="error__correlation">
                        Error id:
                        {correlationId}
                    </div>

                    <Button className="Error__Button" onClick={redirect} label="Try again" />
                </div>
            </div>
        </div>
    );
}

Error.propTypes = {
    error: PropTypes.any
};

export default connect(
    state => state.profile,
    dispatch => bindActionCreators(actions, dispatch)
)(Error);
