import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { actions } from '../../store/Profile';
import Spinner from '../Spinner';

import './Home.css';

function Home({ updateProfile }) {
    const navigate = useNavigate();

    useEffect(() => {
        const showError = () => {
            navigate('/error');
        };

        updateProfile().catch(showError);
    }, [updateProfile, navigate]);

    return (
        <div className="Home">
            <Spinner />
            <div className="Home__Text">
                <h2>authenticating — you will be redirected soon</h2>
            </div>
        </div>
    );
}

Home.propTypes = {
    updateProfile: PropTypes.func.isRequired
};

export default connect(
    state => state.profile,
    dispatch => bindActionCreators(actions, dispatch)
)(Home);
