import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { Navigate } from 'react-router-dom';

function PrivateRoute({ children, isAuthenticated, redirectTo }) {
    return isAuthenticated ? children : <Navigate to={redirectTo} />;
}

PrivateRoute.propTypes = {
    children: PropTypes.any.isRequired,
    isAuthenticated: PropTypes.bool.isRequired,
    redirectTo: PropTypes.string.isRequired
};

export default connect(state => {
    const { token } = state.authentication;
    return { isAuthenticated: token != null && token.length > 0 };
})(PrivateRoute);
