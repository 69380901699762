import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import store from 'store2';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

import { operations } from '../../store/Authentication';

function Authentication({ authenticate, saveToken }) {
    const navigate = useNavigate();

    const isTokenExpired = token => {
        if (token == null) {
            return true;
        }

        const now = Date.now().valueOf() / 1000;
        const decoded = jwtDecode(token);

        return typeof decoded.exp !== 'undefined' && decoded.exp < now;
    };

    useEffect(() => {
        const token = store('authentication_token');

        if (isTokenExpired(token)) {
            authenticate();
            return;
        }

        saveToken(token);
        navigate('/');
    }, [authenticate, saveToken, navigate]);

    return null;
}

Authentication.propTypes = {
    authenticate: PropTypes.func.isRequired,
    saveToken: PropTypes.func.isRequired
};

export default connect(
    state => state.authentication,
    dispatch => bindActionCreators(operations, dispatch)
)(Authentication);
