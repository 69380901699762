import fetch from './fetch';

async function login(token) {
    const url = 'api/login';
    return await fetch(url, 'POST', {}, token);
}

const services = {
  login
};

export default services;
