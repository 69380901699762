/* eslint-disable react/jsx-filename-extension */
import React from 'react';

import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { PrivateRoute, Authentication, Token } from './components/Authentication';
import Home from './components/Home';
import Error from './components/Error';

import './App.css';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/token" element={<Token />} />
                <Route path="/login" element={<Authentication />} />
                <Route path="/error" element={<Error />} />
                <Route
                    path="/"
                    element={(
                        <PrivateRoute redirectTo="/login">
                            <Home />
                        </PrivateRoute>
                    )}
                />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
