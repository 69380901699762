import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import store from 'store2';
import querystring from 'query-string';
import { useNavigate, useLocation } from 'react-router-dom';

import { operations } from '../../store/Authentication';

function Token({ saveToken }) {
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const { hash } = location;

        if (!hash) {
            navigate('/notauthorized');
            return;
        }

        const { access_token } = querystring.parse(hash);
        saveToken(access_token);
        store('authentication_token', access_token);
        navigate('/');
    }, [saveToken, location, navigate]);

    return null;
}

Token.propTypes = {
    saveToken: PropTypes.func.isRequired
};

export default connect(
    state => state.authentication,
    dispatch => bindActionCreators(operations, dispatch)
)(Token);
